import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from './ErrorHandler';

const baseUrl= 'http://overbookingapp.com/api/';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private errorHandler : ErrorHandler
  ) { }

  checkIfFirstAssigned(userId : number){
    return this.http.get<any>(baseUrl + 'user/first/assign/' + userId)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  getNumberOfInvited(userId : number){
    return this.http.get<any>(baseUrl + '/user/promo/count/' + userId)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }



}
