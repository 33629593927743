import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from './ErrorHandler';

@Injectable({
  providedIn: 'root'
})

export class AccountingService {
  public selectedStructureAccountingData : any = {};
  public totalStructureIncoming : number;
  public totalStructureOutcoming : number;
  public orderByQuarter : boolean = true;

  constructor(
    private http : HttpClient,
    private util : UtilsService,
    private errorHandler : ErrorHandler
  ) { }

  getAccounting(params : any){
    return this.http.get<any>(this.util.baseUrl + 'accounting', { params : params })
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }
}
