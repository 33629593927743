import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from './ErrorHandler';

export enum SwitchesNames{
  OverbookingList = 'overbookings-list',
  OverbookingSendChoiceStructure = 'overbooking-send-choice-structure',
  Notifications = 'notifications',
  Profile = 'profile',
  ChangeOverbookingRadius = 'change-overbooking-radius'
}

@Injectable({
  providedIn: 'root'
})
export class SwitchesService {
  constructor(
    private http: HttpClient,
    private util : UtilsService,
    private errorHandler : ErrorHandler
  ) {}

  getSwitches(){
    return this.http.get<any>(this.util.baseUrl + 'switches')
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }
}
