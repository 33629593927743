import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { catchError } from "rxjs/operators";
import { ErrorHandler } from "./ErrorHandler";
import qs from "qs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  public isComingFromProfile: boolean = false;
  public editingStructure: any = {};

  constructor(
    private http: HttpClient,
    private util: UtilsService,
    private errorHandler: ErrorHandler
  ) {}

  editProfile(profileInfo: any) {
    return this.http
      .put<any>(
        this.util.baseUrl + "user/profile",
        qs.stringify(profileInfo),
        httpOptions
      )
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  changeNotificationSetting(settings: any) {
    return this.http
      .put<any>(
        this.util.baseUrl + "user/settings",
        qs.stringify(settings),
        httpOptions
      )
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  changeStructureInfo(params: any) {
    return this.http
      .put<any>(
        this.util.baseUrl + "structure/info",
        qs.stringify(params),
        httpOptions
      )
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  changeStructureLegal(params: any) {
    return this.http
      .put<any>(
        this.util.baseUrl + "structure/legal",
        qs.stringify(params),
        httpOptions
      )
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  changeStructureRankings(params: any) {
    return this.http
      .put<any>(
        this.util.baseUrl + "structure/rankings",
        qs.stringify(params),
        httpOptions
      )
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  updateAmenity(params: any) {
    return this.http
      .put<any>(
        this.util.baseUrl + "structure/amenities",
        qs.stringify(params),
        httpOptions
      )
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  checkSettings(userId: number) {
    return this.http
      .get<any>(this.util.baseUrl + "user/settings/" + userId)
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  getShareCode(userId: number) {
    return this.http
      .get<any>(this.util.baseUrl + "user/shareCode/" + userId)
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  checkPassword(userId: number, password: string) {
    var obj = qs.stringify({
      id: userId,
      pass: password,
    });

    return this.http
      .post<any>(this.util.baseUrl + "user/password/check", obj, httpOptions)
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }

  deleteAccount(userId) {
    return this.http
      .delete<any>(this.util.baseUrl + "user/" + userId)
      .pipe(catchError((error) => this.errorHandler.errorHandler(error)));
  }
}
