import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from './ErrorHandler';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})

export class CitiesService {
  public selectedCities : Array<Object> = [];

  constructor(
    private http: HttpClient,
    private util : UtilsService,
    private errorHandler : ErrorHandler
  ) { }

  getAll(){
    return this.http.get<any>(this.util.baseUrl + 'cities')
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }
}
