import { Injectable } from '@angular/core';
import { Structure } from '../models/Structure';

@Injectable({
  providedIn: 'root'
})

export class OverbookingRequestService {
public overbooking : any = {};
public selectedStructure: Structure = {};
public notOverbookingStructure : Structure = {};

  constructor() { }
}
