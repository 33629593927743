import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  //Massimo raggio che può raggiungere un ob quando viene modificato.
  public maxEditOverbookingRadius = 20;
  //Zoom della mappa.
  public mapZoom: number = 17;
  //Raggio limite della ricerca nella mappa.
  public mapMaxSearchingRadius : number = 20000;
  //Numero minimo di parole dopo la quale comincerà a popolare i risultati della ricerca indirizzo.
  public minSearchingAddressLength : number = 4;

  constructor() {}
}
