import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { ErrorHandler } from './ErrorHandler';
import qs from 'qs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded'
  })
};

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  public newNotifications : any = [];
  public oldNotifications : any = [];
  public newCount : number = 0;

  constructor(
    private http : HttpClient,
    private util : UtilsService,
    private errorHandler : ErrorHandler
  ) { }

  notifications( userId : number ) {
    return this.http.get<any>(this.util.baseUrl + 'notifications/' + userId)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error)),
    );
  }

  markAsRead(model : any){
    return this.http.put<any>(this.util.baseUrl + 'notification/read', qs.stringify(model), httpOptions)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  markAsReadAvailabilityReceived(model : any){
    return this.http.put<any>(this.util.baseUrl + 'notification/read/availability', qs.stringify(model), httpOptions)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }
}
