import {trigger,state,style,animate,transition} from '@angular/animations';

export const fadeOut = [
  // the fade-in/fade-out animation.
  trigger('simpleFadeAnimation', [

    // the "in" style determines the "resting" state of the element when it is visible.
    state('out', style({opacity: 0})),

    // fade in when created. this could also be written as transition('void => *')
    transition(':enter', [
      style({opacity: 1}),
      animate(4000 )
    ]),

    // fade out when destroyed. this could also be written as transition('void => *')
    transition(':leave',
    animate(4000, style({opacity: 1})))
  ])
];
